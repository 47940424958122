<template>
    <div v-if='model && !model.is_null'>
        <form @submit='save' @change='formIsDirty'>
            <div class='grid p-fluid formgrid mt-6'>
                <div class='field col-12  md:col-8'>
                    <span class='p-float-label'>
                        <InputText id='from_url'
                                   type='text'
                                   v-model="v$.model.from_url.$model"
                                   :class="{'p-invalid': v$.model.from_url.$errors.length}"
                        />
                        <label for='from_url'>Url richiesto (omettere il dominio)</label>
                    </span>
                    <small><a :href='getFrontendUrl(model.from_url)' target='_blank'>{{getFrontendUrl(model.from_url)}}</a></small>
                </div>
            </div>
            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12  md:col-8'>
                    <div class="p-inputgroup">
                        <span class='p-float-label'>
                            <InputText id='to_url'
                                       type='text'
                                       v-model="v$.model.to_url.$model"
                                       :class="{'p-invalid': v$.model.to_url.$errors.length}"
                            />
                            <label for='to_url'>Url destinazione (omettere il dominio)</label>
                        </span>
                    </div>
                    <small><a :href='getFrontendUrl(model.to_url)' target='_blank'>{{getFrontendUrl(model.to_url)}}</a></small>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12 md:col-3'>
                    <span class='p-float-label'>
                        <Dropdown v-model='v$.model.code.$model'
                                  id='code'
                                  dataKey='value'
                                  :options='dd_redirect_codes'
                                  optionLabel='label'
                                  :class="{'p-invalid': v$.model.code.$errors.length}"
                                  option-value='value'
                                  :filter='true'
                                  filterPlaceholder='Cerca' />
                        <label for='code'>Codice</label>
                    </span>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12 md:col-3'>
                    <span class='p-float-label'>
                        <Dropdown v-model='model.active'
                                  id='active'
                                  dataKey='value'
                                  :options='YesNoSelects'
                                  optionLabel='label'
                                  option-value='value'
                                  />
                        <label for='active'>Attivo</label>
                    </span>
                </div>
            </div>

            <div class='grid p-justify-center mt-4'>
                <div class='col-12 text-center'>
                    <SaveButton @click='save' :load='loading'/>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('Web/WebRedirects');
import useVuelidate from '@vuelidate/core'

import model from './model';

import Navigations from '../../../mixins/navigations';
import Notifications from '../../../mixins/notifications';
import DdCodes from '../../../mixins/dd_redirect_codes';

export default {
    mounted(){
        this.model = this.row;
    },
    setup () {
        return { v$: useVuelidate() }
    },
    validations () {
        return model.modelValidation;
    },
    computed: {
        ...mapGetters(['row', 'loading']),
    },
    watch: {
        row(n){
            this.model = n;
        }
    },
    methods: {
        ...mapActions(['savePlain','createPlain']),
        ...mapMutations(['formIsDirty', 'formIsClean']),
        async save () {
            const result = await this.v$.$validate()
            if (!result) {
                this.ShowError("Errore", "Alcuni campi del form non sono corretti");
                return
            }
            const save = (this.model.id > 0) ? this.savePlain : this.createPlain;
            const changeRoute = (this.model.id === 0);

            save(this.model).then( resp => {
                this.formIsClean();
                if (changeRoute){
                    this.navigateToId(resp.id).then( () => {
                        this.ShowSuccess('Ottimo', 'Record inserito con successo');
                        this.ShowWarning('Attenzione', "La modifica sarà live entro qualche minuto");
                    });
                    return;
                }
                this.ShowSuccess('Ottimo', 'Record modificato con successo');
                this.ShowWarning('Attenzione', "La modifica sarà live entro qualche minuto");

            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        }
    },
    mixins: [
        Navigations,
        Notifications,
        DdCodes
    ]
};
</script>
