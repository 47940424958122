<template>
    <Card>
        <template #title>
            <h5 v-if='id === 0'>Nuovo record</h5>
            <h5 v-else>{{ pageTitle }}
            </h5>
        </template>
        <template #content v-if='row'>
            <PlainForm />
        </template>
    </Card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers('Web/WebRedirects');

import Factory from './model';
import Notifications from '../../../mixins/notifications';
import Navigations from '../../../mixins/navigations';
import PageGuards from '../../../mixins/guards';

import PlainForm from './Plain';

export default {
    beforeRouteLeave(to, from, next){
      this.beforeRouteLeaveCheck(to, from, next);
    },
    beforeRouteUpdate(to, from, next){
        this.beforeRouteUpdateCheck(to, from, next);
    },
    mounted(){
        console.log("ID is ", this.id);
        this.loadData(this.id);
    },
    computed: {
        ...mapGetters(['row', 'loading', 'dirtyForm']),
    },
    methods: {
        ...mapActions(['fetchOne']),
        ...mapMutations(['setRow', 'formIsClean']),
        loadData(id){
            if (id === 0){
                this.setRow(Factory.newModel(this.languages));
            } else {
                this.fetchOne(id).then(res => {
                    this.setPageTitle("Modifica record " + res.from_url);
                }).catch(err => {
                    this.ShowStandardError(err.response);
                }).finally(() => {});
            }

        }
    },
    props: {
        id: {
            default: 0,
            type: Number
        }
    },
    watch: {
        id(n, o){
            if (n !== o) {
                this.loadData(n);
            }
        },
        'model.to_url'(){
            this.testedDestination = false;
        }
    },
    data(){
        return {
            testedDestination: false
        }
    },
    mixins: [
        Notifications,
        Navigations,
        PageGuards
    ],
    components: {
        PlainForm
    }
}
</script>
